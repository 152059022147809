import React from 'react';
import Helmet from 'react-helmet';
import Blocks from '~/components/blocks';

const Page = ({ pageContext }) => {
  const { title, blocks } = pageContext;

  return (
    <>
      {/* Head Data */}
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {/* Page Content */}
      <Blocks blocks={blocks} />
    </>
  );
};

export default Page;
